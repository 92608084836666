import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty, difference} from "lodash";

type ProtectedRouteProps = {
    permissionKeyList: Array<string>;
    outlet: JSX.Element;
};
  
export const PermissionProtectedRoute = ({permissionKeyList, outlet}: ProtectedRouteProps) => {
    const { permission } = useSelector((state: any) => state.auth);

    if (isEmpty(permission?.permissions[0])) {return null;}

    if (isEmpty(permissionKeyList) || permissionKeyList.every(i => permission.permissions[0].includes(i))) {return outlet;}

    return <Navigate to="/403" replace />;
};